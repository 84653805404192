<template>
  <form v-if="formFields" @submit.prevent.stop="save" class="fit">
    <q-card flat bordered>
      <q-card-section>
        <base-input-text v-bind="formInputProps('contenu')" :color="color" v-model="formData.contenu" ref="contenu" />
      </q-card-section>

      <q-card-section class="row justify-end">
        <div class="col col-auto q-gutter-sm">
          <q-btn :color="color" size="sm" outline @click.native="reset">{{$t('form.cancel')}}</q-btn>
          <q-btn :color="color" size="sm" unelevated type="submit">{{$t('form.save')}}</q-btn>
        </div>
      </q-card-section>
    </q-card>
  </form>
</template>

<script>
import { BaseForm } from '../../mixins'

export default {
  name: 'CommentForm',
  mixins: [BaseForm],
  props: {
    'form_model': { type: String, default: 'comments' },
    'action': { type: String, default: 'comments/saveComment' },
    'color': { type: String, default: 'primary' },
    'model_type': { type: String, required: true },
    'model_id': { type: [String, Number], required: true }
  },

  mounted () {
    if (!this.formFields) {
      this.$store.dispatch('comments/fetchFormFields')
    }

    this.formData = {
      ...this.formData,
      model_type: this.model_type,
      model_id: this.model_id
    }
  }
}
</script>

<style lang="stylus" scoped>
</style>
