<template>
  <base-timeline :items="comments" date_field="created_at" :color="color">
    <template slot-scope="entry">
      <CommentCard :color="color" :comment="entry.item" @menuClick="onMenuClick" @success="$emit('updated')" />
    </template>
  </base-timeline>
</template>

<script>
import { CommentCard } from './'
import { mapGetters } from 'vuex'

export default {
  name: 'CommentsTimeline',
  components: { CommentCard },
  props: {
    comments: { type: Array, default: () => ([]) },
    color: { type: String }
  },

  computed: {
    ...mapGetters({
      isAdmin: 'auth/isAdmin'
    })
  },

  methods: {
    getActionsForComment (comment) {
      return [
        { label: this.$t('comments.delete_comment'), payload: { name: 'comment-delete', params: { id: comment.id } } }
      ]
    },

    onMenuClick ({ name, params }) {
      if (name === 'comment-delete') {
        this.$emit('delete', params.id)
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
</style>
