<template>
  <q-card flat>
    <form @submit.prevent.stop="save">
      <q-card-section class="q-pa-sm">
        <q-item class="q-pa-sm">
          <q-item-section>
            <div class="column">
              <div class="row q-pa-sm justify-between">
                <q-item-label caption>{{$formatDate(comment.created_at, 'YYYY/MM/DD HH:mm:ss')}}</q-item-label>
                <q-item-label caption>{{comment.auteur.display_name}}</q-item-label>
              </div>

              <q-input v-if="isAdmin || isAuthor"
                v-model="formData.contenu" ref="contenu"
                @keyup.native="$refs['contenu'].resetValidation()" @focus="isFormOpen = true"
                :rules="[ val => !!val || $t('form.required_field') ]"
                :color="color" outlined autogrow class="q-pa-none q-pr-sm" />
              <div v-else>{{comment.contenu}}</div>

              <div v-if="isFormOpen" class="row q-pa-sm q-gutter-sm justify-end">
                <q-btn :color="color" size="sm" outline @click.native="reset">{{$t('form.cancel')}}</q-btn>
                <q-btn :color="color" size="sm" unelevated type="submit">{{$t('form.save')}}</q-btn>
              </div>
            </div>
          </q-item-section>

          <q-separator vertical />

          <q-item-section side>
            <q-btn v-if="isAdmin || isAuthor" flat dense size="sm" icon="delete_outline"
              @click="$emit('menuClick', { name: 'comment-delete', params: { id: comment.id } })" />
          </q-item-section>
        </q-item>
      </q-card-section>
    </form>
  </q-card>
</template>

<script>
import { BaseForm } from '../../mixins'
import { mapGetters } from 'vuex'

export default {
  name: 'CommentCard',
  mixins: [BaseForm],
  props: {
    'comment': { type: Object, required: true },
    'menuOptions': { type: Array, default: () => ([]) },
    'color': { type: String, default: 'primary' },
    'form_model': { type: String, default: 'comments' },
    'action': { type: String, default: 'comments/saveComment' }
  },

  data () {
    return {
      isFormOpen: false
    }
  },

  computed: {
    ...mapGetters({
      isAdmin: 'auth/isAdmin',
      currentUser: 'auth/getCurrentUser',
      formFields: 'comments/getFormFields'
    }),

    isAuthor () {
      return this.currentUser.id === this.comment.auteur.id
    },

    model_type () {
      if (this.comment.model_type === 'App\\Tache') { return 'tache' }
      return this.comment.model_type
    },

    is_favorite () {
      return false
    }
  },

  mounted () {
    let model_type, model_id = this.comment.model_id
    if (this.comment.model_type === 'App\\Entity') { model_type = 'entity' }
    if (this.comment.model_type === 'App\\Wallet') { model_type = 'wallet' }
    if (this.comment.model_type === 'App\\Agent') { model_type = 'agent' }
    if (this.comment.model_type === 'App\\Cible') { model_type = 'cible' }

    this.formData = {
      ...this.formData,
      id: this.comment.id,
      contenu: this.comment.contenu,
      model_type,
      model_id
    }
  },

  methods: {
    reset () {
      this.isFormOpen = false
    }
  }
}
</script>

<style>
</style>
