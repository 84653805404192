<template>
  <div class="row" v-if="task">
    <div class="col col-md-8">
      <div class="column">
        <div class="col col-auto">
          <base-add-button :label="$t('comments.add_comment')" color="tasks"
            v-if="!isFormOpen" @click="isFormOpen = true" />

          <CommentForm v-if="isFormOpen"
            model_type="tache" :model_id="$route.params.id" color="tasks"
            @reset="isFormOpen = false" @success="onSuccess" />
        </div>
      </div>

      <div class="col">
        <CommentsTimeline :comments="comments" color="tasks"
          @updated="fetchComments" @delete="deleteComment" />
      </div>
    </div>
  </div>
</template>

<script>
import { Loading } from 'quasar'
import { CommentForm, CommentsTimeline } from '../../components/comments'
import { mapGetters } from 'vuex'

export default {
  name: 'TaskComments',
  components: { CommentForm, CommentsTimeline },

  preFetch ({ store, currentRoute, previousRoute, redirect, ssrContext }) {
    if (currentRoute.params.id) {
      Loading.show()
      store.dispatch('comments/fetchFormFields').then(() => {
        return store.dispatch('tasks/fetchTaskComments', { id: currentRoute.params.id, rowsPerPage: 0 })
          .then(comments => { store.commit('comments/setComments', comments) })
      }).finally(() => Loading.hide())
    }
  },

  data () {
    return {
      isFormOpen: false
    }
  },

  computed: {
    ...mapGetters({
      isAdmin: 'auth/isAdmin',
      task: 'tasks/getCurrentTask',
      comments: 'comments/getComments'
    })
  },

  methods: {
    onSuccess () {
      this.isFormOpen = false
      this.$store.dispatch('tasks/getTask', this.$route.params.id)
      this.fetchComments()
    },

    fetchComments () {
      this.$store.dispatch('tasks/fetchTaskComments', { id: this.$route.params.id, rowsPerPage: 0 })
        .then(comments => { this.$store.commit('comments/setComments', comments) })
    },

    deleteComment (id) {
      this.$q.dialog({
        label: this.$t('comments.delete_comment'),
        persistent: true,
        message: this.$t('comments.delete_comment_confirm'),
        ok: {
          label: this.$t('form.delete'),
          unelevated: true,
          color: 'negative'
        },
        cancel: {
          flat: true,
          color: 'primary'
        }
      }).onOk(() => {
        this.$store.dispatch('comments/deleteComment', id)
          .then(() => {
            this.$q.notify({
              message: this.$t('comments.delete_success'),
              color: 'positive'
            })
            return this.fetchComments()
          }).catch(error => this.$q.notify({
            message: this.$t(error),
            color: 'negative'
          })).then(() => this.$store.dispatch('tasks/getTask', this.$route.params.id))
      })
    }
  }
}
</script>

<style>
</style>
